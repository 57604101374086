@charset "UTF-8";
/*!
Typeplate : Starter Kit
URL ........... http://typeplate.com
Version ....... 3.0.2
Github ........ https://github.com/typeplate/starter-kit
Authors ....... Dennis Gaebel (@gryghostvisuals) & Zachary Kain (@zakkain)
License ....... Creative Commmons Attribution 3.0
License URL ... https://github.com/typeplate/starter-kit/blob/master/license.txt
*/

html {
  font-size: initial;
}

.typl8-tera,
.typl8-giga,
.typl8-mega,
h1,
.typl8-alpha,
h2,
.typl8-beta,
h3,
.typl8-gamma,
h4,
.typl8-delta,
h5,
.typl8-epsilon,
.typl8-zeta,
h6 {
  text-rendering: optimizeLegibility;
  line-height: 1;
  margin-top: 1em;
  color: #222;
}

blockquote + figcaption cite {
  display: block;
  font-size: inherit;
  text-align: right;
}

body {
  word-wrap: break-word;
}

pre code {
  word-wrap: normal;
}

html {
  font: normal 112.5% / 1.65 serif;
  font-family: Poppins, sans-serif;
}

body {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #444;
}

.typl8-tera {
  font-size: 6.5em;
  margin-bottom: 3.93939rem;
}

.typl8-giga {
  font-size: 5em;
  margin-bottom: 3.0303rem;
}

.typl8-mega,
h1 {
  font-size: 4em;
  margin-bottom: 2.42424rem;
}

.typl8-alpha,
h2 {
  font-size: 3.33333em;
  margin-bottom: 2.0202rem;
}

.typl8-beta,
h3 {
  font-size: 2.66667em;
  margin-bottom: 1.61616rem;
}

.typl8-gamma,
h4 {
  font-size: 2em;
  margin-bottom: 1.21212rem;
}

.typl8-delta,
h5 {
  font-size: 1.33333em;
  margin-bottom: 0.80808rem;
}

.typl8-epsilon {
  font-size: 1.16667em;
  margin-bottom: 0.70707rem;
}

.typl8-zeta,
h6 {
  font-size: 1em;
  margin-bottom: 0.60606rem;
}

small {
  font-size: 65%;
}

input,
abbr,
acronym,
blockquote,
code,
kbd,
q,
samp,
var {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre {
  white-space: pre;
}

pre code {
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

code {
  white-space: pre;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

abbr {
  -webkit-font-variant: small-caps;
  -moz-font-variant: small-caps;
  -ms-font-variant: small-caps;
  font-variant: small-caps;
  font-weight: 600;
  text-transform: lowercase;
  color: gray;
}

abbr[title]:hover {
  cursor: help;
}

.typl8-drop-cap:first-letter {
  float: left;
  margin: 10px 10px 0 0;
  padding: 0 20px;
  font-size: 4em;
  font-family: inherit;
  line-height: 1;
  text-indent: 0;
  background: transparent;
  color: inherit;
}

p + .typl8-drop-cap {
  text-indent: 0;
  margin-top: 0;
}

/**
 * Lining Definition Style Markup
 *
  <dl class="typl8-lining">
    <dt><b></b></dt>
    <dd></dd>
  </dl>
 *
 * Extend this object into your markup.
 *
 */
.typl8-lining dt,
.typl8-lining dd {
  display: inline;
  margin: 0;
}

.typl8-lining dt + dt:before,
.typl8-lining dd + dt:before {
  content: '\A';
  white-space: pre;
}

.typl8-lining dd + dd:before {
  content: ', ';
}

.typl8-lining dd:before {
  content: ': ';
  margin-left: -0.2rem;
}

/**
 * Dictionary Definition Style Markup
 *
  <dl class="typl8-dictionary-style">
    <dt><b></b></dt>
    <dd></dd>
  </dl>
 *
 * Extend this object into your markup.
 *
 */
.typl8-dictionary-style dt {
  display: inline;
  counter-reset: definitions;
}

.typl8-dictionary-style dt + dt:before {
  content: ', ';
  margin-left: -0.2rem;
}

.typl8-dictionary-style dd {
  display: block;
  counter-increment: definitions;
}

.typl8-dictionary-style dd:before {
  content: counter(definitions, decimal) '. ';
}

/**
 * Blockquote Markup
 *
    <figure>
      <blockquote cite="">
        <p></p>
      </blockquote>
      <figcaption>
        <cite>
          <small><a href=""></a></small>
        </cite>
      </figcaption>
    </figure>
 *
 * Extend this object into your markup.
 *
 */
/**
 * Pull Quotes Markup
 *
  <aside class="typl8-pull-quote">
    <blockquote>
      <p></p>
    </blockquote>
  </aside>
 *
 * Extend this object into your custom stylesheet.
 *
 */
.typl8-pull-quote {
  position: relative;
  padding: 1em;
}

.typl8-pull-quote:before,
.typl8-pull-quote:after {
  height: 1em;
  opacity: 0.5;
  position: absolute;
  font-size: 4em;
  color: #dc976e;
}

.typl8-pull-quote:before {
  content: '“';
  top: 0;
  left: 0;
}

.typl8-pull-quote:after {
  content: '”';
  bottom: 0;
  right: 0;
}

/**
 * Figures Markup
 *
  <figure>
    <figcaption>
      <strong>Fig. X.X | </strong><cite title=""></cite>
    </figcaption>
  </figure>
 *
 * Extend this object into your markup.
 *
 */
/**
 * Footnote Markup : Replace 'X' with your unique number for each footnote
 *
  <article>
    <p><sup><a href="#fn-itemX" id="fn-returnX"></a></sup></p>
    <footer>
      <ol class="foot-notes">
        <li id="fn-itemX"><a href="#fn-returnX">↩</a></li>
      </ol>
    </footer>
  </article>
 *
 * Extend this object into your markup.
 *
 */
