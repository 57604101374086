.row {
  max-width: 50rem;
  margin: 0 auto 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.person {
  max-width: 13rem;
  text-align: center;
  flex: 1 1 100px;
  min-width: 220px;
}
