.wrap {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  position: sticky;
  top: 65px;
  z-index: 5;
  padding: 1rem 2rem 0.5rem;
  border-bottom: 1px solid white;
}

.wrap :global(.ant-steps) {
  margin: 0 auto;
}
.wrap
  :global(.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description) {
  max-width: 100%;
}
