@import '~antd/dist/antd.less';

/* Antd overwrites */
.ant-input-number-handler-wrap {
  opacity: 1;
}

:root {
  --page-width: 37rem;
}

.content {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 0 1rem;
}
.content p {
  font-size: 18px;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
.t-light {
  font-weight: 300;
}
.t-regular {
  font-weight: 400;
}
.t-bold {
  font-weight: 700;
}
.t-all-caps {
  text-transform: uppercase;
}

.content p.t-lead,
.t-lead {
  font-size: 1.4rem;
}
.t-right {
  text-align: right;
  margin-left: auto;
}
.t-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.t-white {
  color: white;
}
.temp-graphic {
  position: sticky;
  bottom: -23vw;
  margin: -21vw 0;
  pointer-events: none;
  // max-height: 600px;
  // width: 100%;
}
.content.after-graphic {
  position: relative;
}

@primary-color: #F00BAA;@font-family: Poppins, sans-serif;@font-size-base: 16px;@font-size-sm: 14px;