.appHeaderWrap {
  height: 71px;
}
.appHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid #fff;
  z-index: 1000;
}
.appHeader :global(.ant-menu) {
  background: none;
}

.appHeaderWrap :global(.ant-page-header-heading-extra) {
  margin: 0;
}
