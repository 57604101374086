.cardRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0rem -2rem;
}
.cardRow > * {
  margin: 1rem;
}

.cardWrap {
  border: none;
  background: none;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
  transition: all 150ms;
  box-shadow: 0 0 0 0 #ffd0f1;
}
.cardWrap.active {
  border-color: #f00baa;
  box-shadow: 0 0 0 8px #ffd0f1;
}
