.wrapper {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.wrapper + .wrapper {
  /* margin-top: 2rem; */
}
.basicCalcWrap {
  max-width: 37.5rem; /* 600px */
  justify-content: flex-end;
  align-items: flex-end;
}
.detailedForm :global(.ant-collapse-item) {
  margin-bottom: 2rem;
}
.detailedForm
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 1rem 0;
}
.detailedForm
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header::before),
.detailedForm
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header::after) {
  display: none;
}
.detailedForm :global(.ant-collapse-extra) {
  text-align: right;
}
.wrapper :global(.ant-row.ant-form-item) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 33%;
  min-width: 10rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.wrapper.basicCalcWrap :global(.ant-row.ant-form-item) {
  max-width: unset;
  min-width: unset;
  margin-right: 0.5rem;
}

.wrapper :global(.ant-row.ant-form-item):last-of-type {
  margin-right: 0;
}
.wrapper :global(.ant-form-item-label) {
  font-weight: 700;
  text-align: left;
}
.numberInput :global(.ant-input-number-input) {
  text-align: right;
  padding-right: 32px;
}
